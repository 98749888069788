import { convertKeysToCamelCase, convertRentalSnakeToCamelCase, handlePageCountCommon } from 'utils/utilCommon'
import { getCountRentalCountRPC } from '../rpc/rpc.api'
import { supabase } from '../axios.instance'

const table = 'tools_rental'
/**
 * 대여 반납 목록
 * @param search
 * @param regionId
 * @param path
 * @returns {Promise<PostgrestResponseSuccess<any>|PostgrestResponseFailure|null>}
 */
export const getToolRentalList = async (search, regionId, path) => {
    try {
        let filter = null
        let paginationInfo = null
        const pageSize = 10
        let realRegionId = ''

        // 필터링
        const searchParams = new URLSearchParams(search)
        const operation = searchParams.get('program_operation')
        const searchInfo = searchParams.get('search')
        const page = searchParams.get('page')
        const sort = searchParams.get('sort')
        const dispatchDate = searchParams.get('dispatchDate')
        if (regionId === 'all') {
            realRegionId = searchParams.get('regionId') ?? 'all'
        } else {
            realRegionId = regionId
        }

        // 목록 RPC query
        const response = await supabase.rpc('get_tools_rental_list', {
            p_page: page, // 페이지
            p_program_operation: operation, // '대여신청','대여확정','반납',
            p_search: searchInfo, // 검색어 입력
            p_region_id: realRegionId, // 'regionId': 특정 지역, 'all' : 모든 지역 (어드민)
            p_sort_order: sort === 'updatedDt' ? 'updated_dt' : 'created_at',
            p_dispatch_date: dispatchDate ?? null,
        })

        // 상단 카운트 정보
        filter = await getCountRentalCountRPC(regionId)
        filter = convertKeysToCamelCase(filter)

        // 페이지 정보
        paginationInfo = handlePageCountCommon(search, response, pageSize)

        // 현재 JSON 데이터 형태로 컨버팅
        if (response.data) {
            const convertedData = response.data.map(convertRentalSnakeToCamelCase)
            if (path !== 'main') {
                response.data = {
                    filterCountInfo: filter,
                    rentalList: convertedData,
                    page: paginationInfo,
                }
            }
            return response
        }
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 대여 반납 상세
 * @param regionId
 * @param rentalId
 * @returns {Promise<PostgrestResponseSuccess<ResultOne>|PostgrestResponseFailure|null>}
 */
export const getToolRentalById = async ({ regionId, rentalId }) => {
    try {
        let response = await supabase
            .rpc('get_rental_details', {
                region_id_params: regionId,
                rental_id_params: rentalId,
            })
            .single()
        if (response && response.data) {
            response = convertKeysToCamelCase(response.data)
        }
        return response
    } catch (error) {
        console.debug(error)
        return null
    }
}

/**
 * 대여 상태 변경
 * @param rentalId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const updateRentalStatus = async rentalId => {
    try {
        const response = await supabase
            .from(table)
            .update({ rental_status: '반납완료' })
            .eq('rental_id', rentalId)
            .select()
        return response
    } catch (error) {
        console.error('Error soft deleting program instructor:', error)
        return null
    }
}
