import {
    buildSupabaseChainFilter,
    convertToolsInventorySnakeToCamelCase,
    convertKeysToCamelCase,
    handlePageCountCommon,
} from 'utils/utilCommon'
import { supabase } from '../axios.instance'
import { getToolsInventoryCountRPC } from '../rpc/rpc.api'
import { supabaseEssentialFilter } from '../common'

const table = 'tools_inventory'

/**
 * 재고관리 목록
 * get
 * @param search
 * @param regionId
 * @returns {Promise<PostgrestFilterBuilder<*, *, GetResult<*, *, *, "*">[], *>|null>}
 */
export const getToolsInventoryList = async (search, regionId, path) => {
    try {
        let filter = null

        const pageSize = 10
        // query
        const { query } = await buildSupabaseChainFilter(table, search, regionId, path)
        const response = await query
        const urlSearchParams = new URLSearchParams(query.url.search)
        const params = Object.fromEntries(urlSearchParams.entries())

        filter = await getToolsInventoryCountRPC(params)
        filter = convertKeysToCamelCase(filter)

        const paginationInfo = handlePageCountCommon(search, response, pageSize)

        if (response.data) {
            // json 데이터 (snake -> camel 변경)
            const convertedData = response.data.map(convertToolsInventorySnakeToCamelCase)

            response.data = {
                filterCountInfo: filter,
                toolsInventoryList: convertedData,
                page: paginationInfo,
            }

            return response
        }

        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 재고관리 상세
 * @param toolsId
 * @param regionId
 * @returns {Promise<*|null>}
 */
export const getToolsInventoryById = async (toolsId, regionId) => {
    try {
        const query = supabase.from(table).select('*').eq('tools_id', toolsId)
        const newQuery = supabaseEssentialFilter(query, regionId)

        const response = await newQuery

        // json 데이터 (snake -> camel 변경)
        const convertedData = response.data.map(convertToolsInventorySnakeToCamelCase)

        const count = await getToolsInventoryCountRPC({ region_id: regionId })

        response.data = {
            result: convertedData,
            count,
        }
        return response.data
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 재고관리 등록
 * @param toolsInventoryData
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const insertToolsInventory = async (toolsInventoryData, regionId) => {
    try {
        const dataWithRegion = Array.isArray(toolsInventoryData)
            ? toolsInventoryData.map(data => ({ ...data, region_id: regionId }))
            : { ...toolsInventoryData, region_id: regionId }

        const response = await supabase.from(table).insert(dataWithRegion).select()

        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 재고관리 수정
 * @param toolsInventoryData
 * @param toolsId
 * @returns {Promise<*|PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure>}
 */
export const editToolsInventory = async (toolsInventoryData, toolsId) => {
    try {
        const response = await supabase.from(table).update(toolsInventoryData).eq('tools_id', toolsId).select()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

/**
 * 재고관리 삭제 상태로 변경
 * @param toolsId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const deleteToolsInventoryById = async toolsId => {
    try {
        const response = await supabase
            .from(table)
            .update({ is_delete: true })
            .eq('tools_inventory_id', toolsId)
            .select()
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}
