import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { getAllUserInfo, logoutUserAsync } from 'store/Slices/user'
import { Dropdown, message, Tooltip } from 'antd'
//import { HeaderMemberIcon, LogoutIcon } from 'assets/Icons'
import { LogoutIcon } from 'assets/Icons'

import 'assets/scss/layout/Header.scss'

const userName = '사용자'

// 문의하기 구글폼 URL
// const contactUrl = 'https://forms.gle/3Gv7z1nFytSAH72F7'
const environment = process.env.REACT_APP_ENVIRONMENT
const isDev = environment === 'dev'

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const { isMaster, regionId, isServiceManager } = userInfo
    const [activeMenuItem, setActiveMenuItem] = useState(null) // 1depth 메뉴
    const [messageApi, contextHolder] = message.useMessage()
    const errorMessage = () => {
        messageApi.open({
            type: 'error',
            content: '로그인 세션이 만료되었습니다.',
            duration: 5,
        })
    }

    const toolMenu = [
        {
            label: '재고 관리',
            key: '/tools/inventory',
        },
        {
            type: 'divider',
        },
        {
            label: '대여 반납관리',
            key: '/tools/rental',
        },
    ]

    const statisticsMenu = [
        {
            label: '지역별 데이터 분석',
            key: '/statistics',
            overlayStyle: {
                fontSize: '12px',
            },
        },
        {
            type: 'divider',
        },
        {
            label: '통합 데이터 분석',
            key: '/statistics/total',
            overlayStyle: { fontSize: '12px' },
        },
    ]
    const commonMenuItems = [
        { id: 1, text: '교육계획 관리', to: '/program' },
        { id: 2, text: '강사정보 관리', to: '/tutors' },
        { id: 3, text: '성과 분석', to: '/statistics' },
    ]
    // 용역사 계정 메뉴
    const serviceManagerMenuItems = [...commonMenuItems, { id: 4, text: '문의하기', to: '/contact' }]

    // 지역 관리자 메뉴
    // const menuItems = [
    //     ...commonMenuItems,
    //     { id: 4, text: '월간보고', to: '/report' },
    //     { id: 5, text: '문의하기', to: '/contact' },
    // ]
    //
    // // 어드민 메뉴
    // const adminMenuItems = [...menuItems, { id: 6, text: '계정관리', to: '/accounts' }]

    // 지역 관리자 메뉴
    const menuItems = [
        ...commonMenuItems,
        // { id: 4, text: '교구재 관리', to: '/tools/inventory' },
        { id: 4, text: '월간보고', to: '/report' },
        { id: 5, text: '문의하기', to: '/contact' },
        // { id: 6, text: '수요조사', to: '/survey' },
    ]
    // 어드민 메뉴
    const adminMenuItems = [...menuItems, { id: 6, text: '계정관리', to: '/accounts' }]

    // isServiceManager : 용역사 계정
    const [menus, setMenus] = useState(
        isMaster ? adminMenuItems : isServiceManager ? serviceManagerMenuItems : menuItems,
    )

    const handleDropdownItemClick = e => {
        navigate(e.key)
    }
    const handleMenuItemClick = index => {
        setActiveMenuItem(index)
    }

    /**
     * URL patname에 따라 메뉴 active 상태 변경
     * @param pathName
     * @param Array
     * @returns {*|null}
     */
    const findMenuItemIdByPathname = (pathName, Array) => {
        const matchingItem = Array.find(item => {
            return item.to.split('/')[1] === pathname.split('/')[1]
        })
        return matchingItem ? matchingItem.id : null
    }

    useEffect(() => {
        let selectedMenuItems
        if (isMaster) {
            selectedMenuItems = adminMenuItems
        } else {
            selectedMenuItems = isServiceManager ? serviceManagerMenuItems : menuItems
        }
        setMenus(selectedMenuItems)
        const menuItemId = findMenuItemIdByPathname(path, selectedMenuItems)
        setActiveMenuItem(menuItemId - 1)
    }, [path, isMaster, isServiceManager])

    useEffect(() => {
        // 로그인 정보가 없으면 로그아웃
        if (!isLogin) {
            dispatch(logoutUserAsync())
            errorMessage()
        }
    }, [isLogin])

    return (
        <div id="header">
            <div className="container">
                <button type="button" className="button-reset-type">
                    <Link to="/">
                        <div className="logo_wrap">
                            <div className="logo" />
                            <span className="h8">성과 관리 시스템 {isDev && '(개발용)'} </span>
                        </div>
                    </Link>
                </button>
                {contextHolder}
                {isLogin && (
                    <div className="menu_wrap">
                        <div className="menu">
                            {menus.map((item, index) => {
                                return (
                                    <button
                                        key={item.id}
                                        type="button"
                                        className={`${
                                            activeMenuItem === index
                                                ? 'active button-reset-type menu-btn'
                                                : 'button-reset-type menu-btn'
                                        }`}
                                        onClick={() => handleMenuItemClick(index)}
                                    >
                                        {item.text === '교구재 관리' ? (
                                            <Dropdown
                                                menu={{
                                                    onClick: handleDropdownItemClick,
                                                    items: toolMenu,
                                                }}
                                                placement="bottom"
                                                overlayClassName="tools_overlay_menu"
                                            >
                                                <Link className="header_menu h92" to={item.to}>
                                                    <span className="slim_text3 tool">{item.text}</span>
                                                </Link>
                                            </Dropdown>
                                        ) : item.text === '성과 분석' ? (
                                            isMaster ? (
                                                <Dropdown
                                                    menu={{
                                                        onClick: handleDropdownItemClick,
                                                        items: statisticsMenu,
                                                    }}
                                                    placement="bottom"
                                                    overlayClassName="tools_overlay_menu"
                                                >
                                                    <Link className="header_menu h92" to={item.to}>
                                                        <span className="slim_text3 tool">{item.text}</span>
                                                    </Link>
                                                </Dropdown>
                                            ) : (
                                                <Link className="header_menu h92" to={item.to}>
                                                    <span className="slim_text3 tool">{item.text}</span>
                                                </Link>
                                            )
                                        ) : (
                                            <Link className="header_menu" to={item.to}>
                                                <span className="slim_text3">{item.text}</span>
                                            </Link>
                                        )}
                                    </button>
                                )
                            })}
                        </div>
                        <div className="login">
                            <div className="info">
                                <div className="sub_title2">
                                    {`[${isMaster ? 'Admin' : regionId}]`} <p className="body2 gray">{userName}님</p>
                                </div>
                            </div>
                            <Tooltip title="로그아웃">
                                <button
                                    type="button"
                                    className="button-reset-type"
                                    onClick={() => dispatch(logoutUserAsync())}
                                >
                                    <LogoutIcon />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Header
