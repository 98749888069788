import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BannerIconAccount } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'

import { getAccountList } from 'api/accounts/accouint.api'

import Spinner from 'components/AntD/Spin'
import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import InfoDialog from 'components/AntD/Dialog'
import Account from 'components/AntD/Dialog/Account'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import { Form } from 'antd'
import ListNotFound from '../PageNotFound/ListNotFound'

const AccountsPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const [form] = Form.useForm()

    const queryParams = new URLSearchParams(window.location.search)
    const [isLoading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [accountList, setAccountList] = useState({})
    const { page, userList } = accountList || {}
    const { currentPage, totalItems, totalPages } = page || {}

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const fetchAccountList = async search => {
        try {
            const response = await getAccountList(search, userInfo.regionId)
            if (response.status === 200 || response.status === 206) {
                setAccountList(response.data)
                setLoading(false)
                return true
            }
            console.error('계정 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    useEffect(() => {
        if (isLogin && userInfo.isMaster) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''

            fetchAccountList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    useEffect(() => {
        if (!modalOpen) {
            form.resetFields()
        }
    }, [modalOpen])

    return (
        <PageBanner title="계정 관리" bannerIcon={<BannerIconAccount />}>
            {isLoading ? (
                <Spinner />
            ) : accountList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    <ListView
                        list={userList.slice(0, 10)}
                        setLoading={setLoading}
                        fetchList={fetchAccountList}
                        form={form}
                        isSubMaster={userInfo.isSubMaster}
                    />
                    <Pagenation
                        title="계정 등록"
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        navigateBtn={() => setModalOpen(true)}
                        isSubMaster={userInfo.isSubMaster}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
            <InfoDialog
                content={
                    <Account
                        form={form}
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                        fetchList={fetchAccountList}
                        accountInfo={userList}
                    />
                }
                centered
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                closable={false}
                isCancel
                submit
                hideFooter
                type={2}
            />
        </PageBanner>
    )
}

export default AccountsPage
