import { Link, useLocation } from 'react-router-dom'
import { Flex } from 'antd'

import * as XLSX from 'xlsx'
import { colorTheme } from 'assets/colorTheme'

import { postProgram, postProgramInstructorMatching } from 'api/program/program.api'
import { findDuplicatedTutorNames, postTutors } from 'api/tutors/tutors.api'
import { insertToolsInventory } from 'api/tools/toolsInventory.api'

import { removeParentheses } from 'utils/utilCommon'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import ColorButton from 'components/AntD/Button/ColorButton'
import { DraggableUpload } from 'components/AntD/Upload'
import ListView from 'components/List/ListView'

import { DownloadIcon24 } from 'assets/Icons'

import styles from './register.module.scss'

/**
 * 교육관리, 강사관리 등록 공통 컴포넌트
 * @param jsonData
 * @param fileName
 * @param setDataState
 * @param modifiedData
 * @param handleConvertjsonData
 * @param setAlertOpen
 * @param setModalOpen
 * @param errorMessage
 * @param hasDuplicateTutorName
 * @param unRegisteredTutorName
 * @param duplicatedError
 * @param registerError
 * @param unregisterError
 * @returns {JSX.Element}
 * @constructor
 */
const Register = ({
    jsonData,
    fileName,
    setDataState,
    modifiedData,
    handleConvertjsonData,
    setAlertOpen,
    setModalOpen,
    errorMessage,
    hasDuplicateTutorName,
    unRegisteredTutorName,
    matchingTutorInfo,
    duplicatedError,
    registerError,
    unregisterError,
    requiredNull,
    requiredNullError,
}) => {
    const { blue } = colorTheme

    const { userInfo } = useSelector(getAllUserInfo)
    const { regionId } = userInfo
    const { pathname } = useLocation()

    const paths = pathname.split('/').filter(path => path !== '')
    const isProgramURL = paths[0] === 'program'
    const isTutorURL = paths[0] === 'tutors'
    const isInventoryURL = paths[1] === 'inventory'
    const isRentalURL = paths[1] === 'rental'
    // let tutorArr = [] // 교육 계획 등록 시 등록되어 있는 강사 정보 배열

    // console.log('tutorArr 11: ', tutorArr)
    // 양식 다운로드
    const handleDownloadForm = () => {
        let fileURL = ''

        if (isProgramURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%80%E1%85%AD%E1%84%8B%E1%85%B2%E1%86%A8%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%B7_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.xlsx`
        }
        if (isTutorURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/%E1%84%80%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A1%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5_%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.xlsx`
        }
        if (isInventoryURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/`
        }
        if (isRentalURL) {
            fileURL = `${process.env.REACT_APP_FILE_BUCKET_CLOUDFRONT_URL}/public/assets/form/`
        }

        if (fileURL === '') {
            return false
        }
        const a = document.createElement('a')
        a.href = fileURL
        a.download = 'file-name.pdf'
        a.click()
        return true
    }

    /**
     * 현재 업로드한 엑셀에서 강사명 동명이인 확인
     * @param data
     * @returns {{indexes: *, name: *}[]|null}
     */
    const findDuplicateNames = data => {
        const nameIndexMap = {} // 이름과 해당 이름이 있는 배열의 인덱스를 저장할 객체

        for (let i = 0; i < data.length; i += 1) {
            const tutorName = data[i].tutor_name

            if (nameIndexMap[tutorName]) {
                // 이미 해당 이름이 맵에 있다면 동명이인이므로 배열의 인덱스를 추가
                nameIndexMap[tutorName].push(i)
            } else {
                // 해당 이름이 맵에 없다면 새로운 배열을 생성하여 인덱스 추가
                nameIndexMap[tutorName] = [i]
            }
        }

        // 동명이인이 있는 경우 해당 정보를 반환, 없으면 null 반환
        const duplicates = Object.entries(nameIndexMap)
            .filter(([, indexes]) => indexes.length > 1)
            .map(([name, indexes]) => ({ name, indexes }))

        return duplicates.length > 0 ? duplicates : null
    }

    /**
     * 중복 강사명으로 구성된 배열
     * @param data
     * @returns {unknown[]}
     */
    const getDuplicateNameArr = data => {
        const uniqueTutorNames = {}
        data.forEach(tutor => {
            const { tutorName } = tutor
            uniqueTutorNames[tutorName] = true
        })

        // 새로운 배열 생성
        return Object.keys(uniqueTutorNames)
    }

    /**
     * 필수값 없는 경우 빨간색으로 표시
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const validateRequiredNull = async data => {
        const hasRequiredNull = data.some(item => {
            return Object.keys(item).some(key => {
                const isRequired = key.endsWith('*')
                const isEmpty = !item[key]

                if (isRequired && isEmpty) {
                    console.log(`필수 값이 비어 있음: 키 = ${key}, 값 = ${item[key]}`)
                }
                return isRequired && isEmpty
            })
        })

        setDataState(prevState => ({
            ...prevState,
            requiredNull: hasRequiredNull,
        }))
    }

    /**
     * 교육계획관리에서
     * 주강사, 보조강사 등록 시
     * 강사가 DB에 존재하는지 확인
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const validateLeadInstructorAndAssistant = async (data, transformedData, file) => {
        // 업로드한 데이터 중 강사명만 파싱
        const tutorNamesArray = transformedData.flatMap(tutor => [
            ...(tutor.lead_Instructors || '').split(',').map(item => item.split('(')[0].trim()),
            ...(tutor.assistants || '').split(',').map(item => item.split('(')[0].trim()),
        ])
        // DB에서 같은 지역 강사 동명이인 찾기 (강사 매칭)
        const res = await findDuplicatedTutorNames(tutorNamesArray, userInfo.regionId)
        // 등록된 강사 정보 일부 저장
        const tutorArr = res.data.map(tutor => ({
            tutorId: Number(tutor.tutorId),
            tutorName: tutor.tutorName,
            startDate: tutor.tutorContractInfo ? tutor.tutorContractInfo.startDate : null,
            endDate: tutor.tutorContractInfo ? tutor.tutorContractInfo.endDate : null,
        }))

        // 강사 매칭이 된 경우
        if (res.data && res.data.length > 0) {
            // DB에서 매칭되는 강사명 중 tutor_name만 모아서 배열로 만들기
            // 예시 : ["홍길남","유관순","장영실"]
            const matchedTutorNamesArray = res.data.map(tutor => tutor.tutorName)

            // 강사 등록 여부
            let hasUnregistered = false

            // data에서 주강사, 보조강사 매칭이 안될 경우 unregistered 값 추가
            // unregistered 값이 있는 경우 강사명 빨간색 표시
            data.forEach(entry => {
                // 주강사 및 보조강사 처리
                const instructorRole = ['주강사', '보조강사']
                instructorRole.forEach(key => {
                    const tutorData = entry[key]

                    if (tutorData) {
                        const tutorList = tutorData.split(',').map(name => name.trim())
                        entry[key] = tutorList.map(name => {
                            const result = matchedTutorNamesArray.includes(removeParentheses(name))
                                ? name
                                : { name, unregistered: true }
                            // 만약 unregistered가 true이면 hasUnregistered를 true로 설정
                            if (result.unregistered) {
                                hasUnregistered = true
                            }
                            return result
                        })
                    } else {
                        entry[key] = null
                    }
                })
            })

            setDataState(prevState => ({
                ...prevState,
                jsonData: data,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
                unRegisteredTutorName: hasUnregistered, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: tutorArr, // 교육관리 등록 시 매칭되는 강사정보
            }))
        } else {
            setDataState(prevState => ({
                ...prevState,
                jsonData: data,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
                unRegisteredTutorName: false, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: tutorArr, // 교육관리 등록 시 매칭되는 강사정보
            }))
        }
    }

    /**
     * 강사 등록 시 강사명 validation
     * @param data
     * @param transformedData
     * @param file
     * @returns {Promise<void>}
     */
    const tutorValidateRegister = async (data, transformedData, file) => {
        // 현재 업로드한 엑셀에서 강사명 동명이인 확인
        const duplicateInfo = findDuplicateNames(transformedData)

        // tutor_name만 모아서 배열로 만들기
        const tutorNamesArray = transformedData.map(tutor => tutor.tutor_name)

        // DB에서 같은 지역 강사 동명이인 찾기
        const res = await findDuplicatedTutorNames(tutorNamesArray, userInfo.regionId)

        if (res.data && res.data.length > 0) {
            // DB에서 같은 지역 강사 동명이인 배열
            const duplicatedNames = getDuplicateNameArr(res.data)

            //동영이인 강사가 있는 경우 duplicated값을 true로 표시하여 기존 배열에 추가
            const updatedData = data.map((tutor, index) => {
                const isDuplicated = duplicatedNames.includes(tutor['성명*'])
                let isInfoDuplicated = false // duplicateInfo가 null인 경우 기본값 설정
                if (duplicateInfo) {
                    isInfoDuplicated = duplicateInfo.some(({ indexes }) => indexes.includes(index))
                }
                //     //duplicated가 true인 경우 강사명 빨간색으로 노출
                return {
                    ...tutor,
                    duplicated: isDuplicated || isInfoDuplicated,
                }
            })

            setDataState(prevState => ({
                ...prevState,
                jsonData: updatedData,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: true, // 강사관리 등록 시
                unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            }))
        } else {
            setDataState(prevState => ({
                ...prevState,
                jsonData: data,
                modifiedData: transformedData,
                isUploaded: true,
                fileName: file.name,
                hasDuplicateTutorName: false, // 강사관리 등록 시 중복 강사명 여부
                unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
                matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            }))
        }
    }

    const validateInventoryRegister = async (data, transformedData, file) => {
        // 재고관리 등록 시 필수값 없는 경우 빨간색으로 표시
        await validateRequiredNull(data)

        setDataState(prevState => ({
            ...prevState,
            jsonData: data,
            modifiedData: transformedData,
            isUploaded: true,
            fileName: file.name,
        }))
    }

    /**
     * 파일 업로드 시 처리
     * @param file
     */
    const handleFileUpload = async file => {
        const reader = new FileReader()
        reader.onload = async event => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]

            // range 지정 (첫번째 cell 제거 후 import)
            const range = XLSX.utils.decode_range(sheet['!ref'])
            range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
            sheet['!ref'] = XLSX.utils.encode_range(range)

            // 엑셀 -> json 변환 (각각의 index값 추가)
            const data = XLSX.utils.sheet_to_json(sheet, { blankrows: false, defval: '' })

            // convert json
            const transformedData = handleConvertjsonData(data)

            if (isTutorURL) {
                await tutorValidateRegister(data, transformedData, file)
            }

            if (isProgramURL) {
                await validateLeadInstructorAndAssistant(data, transformedData, file)
            }

            if (isInventoryURL) {
                await validateInventoryRegister(data, transformedData, file)
            }
        }
        reader.readAsBinaryString(file)
    }

    const handleDeleteFile = () => {
        setDataState(prevState => ({
            ...prevState,
            jsonData: [],
            isUploaded: false,
            fileName: '',
            hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
            unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
            matchingTutorInfo: null, // 교육관리 등록시 강사매칭정보
            requiredNull: null, // 필수값 누락 여부
        }))
    }

    const handleRegisterTutorAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            try {
                const response = await postTutors(modifiedData)
                if (response.status === 201) {
                    setModalOpen(true)
                } else if (response.code === 'ERR_BAD_REQUEST') {
                    errorMessage(registerError)
                } else {
                    errorMessage(registerError)
                }
                // TODO: 로그인 세션 분기처리 필요
                // else if (data.error.message === '로그인 해 주세요.') {
                //     loginErrorMessage()
                //     navigate('/')
                // }
            } catch (error) {
                console.error('강사 목록을 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        }
        return false
    }

    /**
     * 괄호 (교시 정보)에 대한 공통 처리 함수
     * @param tutorWithHour
     * @returns {{tutor_name: *, class_hour: (number|null)}|{tutor_name: *, class_hour: null}}
     */
    const parseTutorWithHour = tutorWithHour => {
        // 괄호값 (교시 정보) 있는지 확인
        const result = tutorWithHour.match(/([^(]+)(?:\((\d+)\))?/)
        if (result) {
            const [, tutor, classHour] = result
            return {
                tutor_name: tutor.trim(),
                class_hours: classHour ? parseInt(classHour, 10) : null,
            }
        }
        return {
            tutor_name: tutorWithHour.trim(),
            class_hours: null,
        }
    }

    /**
     * 강사 타입, 교시 매칭
     * 예시 : {tutor_type: 'lead_Instructors', program_id: 198, tutor_name: '홍길동', class_hour: null}
     * @param inputArray
     * @returns {*}
     */
    const matchingTutorTypeAndClassHour = inputArray => {
        return inputArray.flatMap(item => {
            const leadInstructorsArray = item.lead_Instructors
                ? item.lead_Instructors.split(',').map(entry => entry.trim())
                : []
            const assistantsArray = item.assistants ? item.assistants.split(',').map(entry => entry.trim()) : []

            const instructors = leadInstructorsArray.map(tutorWithHour => ({
                tutor_type: '주강사',
                program_id: item.program_id,
                ...parseTutorWithHour(tutorWithHour),
            }))

            const assistants = assistantsArray.map(tutorWithHour => ({
                tutor_type: '보조강사',
                program_id: item.program_id,
                ...parseTutorWithHour(tutorWithHour),
            }))

            return [...instructors, ...assistants]
        })
    }

    const handleRegisterProgramAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            try {
                // 주강사, 보조강사 값을 제외한 새로운 객체 생성
                const newData = []
                const instructorsArray = [] // 배열로 관리할 배열

                Object.keys(modifiedData).forEach(key => {
                    const { lead_Instructors, assistants, ...newItem } = modifiedData[key]
                    newData[key] = newItem

                    // lead_Instructors와 assistants를 같은 배열로 관리
                    if (lead_Instructors) {
                        instructorsArray.push(...lead_Instructors.split(',').map(item => item.trim()))
                    }

                    if (assistants) {
                        instructorsArray.push(...assistants.split(',').map(item => item.trim()))
                    }
                })

                // 데이터 중 주강사와, 보조강사 데이터를 분리한 배열
                const newArray = Object.values(modifiedData).map(item => {
                    const lead_Instructors = item.lead_Instructors
                        ? item.lead_Instructors
                              .split(',')
                              .map(entry => entry.trim())
                              .join(', ')
                        : null
                    const assistantsArray = item.assistants
                        ? item.assistants
                              .split(',')
                              .map(entry => entry.trim())
                              .join(', ')
                        : null

                    return {
                        lead_Instructors,
                        assistants: assistantsArray,
                    }
                })

                // 교육 먼저 등록
                const response = await postProgram(newData)

                if (response && response.status === 201) {
                    const { data } = response
                    if (data && data.length > 0) {
                        const allNull = newArray.every(
                            item => item.lead_Instructors === null && item.assistants === null,
                        )

                        // 주강사 보조강사 정보가 존재할 때
                        if (allNull === false) {
                            // 방금 등록한 프로그램의 이름과 id값 배열 저장
                            const registeredProgramInfo = data.map(item => ({
                                program_name: item.program_name,
                                program_id: item.program_id,
                            }))

                            // 주강사와 보조강사 정보에 프로그램 ID값 매칭
                            const combinedArray = newArray.map((item, index) => ({
                                lead_Instructors: item.lead_Instructors ? item.lead_Instructors : null,
                                assistants: item.assistants ? item.assistants : null,
                                program_id: registeredProgramInfo[index].program_id,
                            }))

                            // 강사타입과 교시 데이터 추가 매칭한 값
                            const matchingResult = matchingTutorTypeAndClassHour(combinedArray)

                            // 각 튜터 정보에 프로그램 ID를 추가하여 정확하게 매칭
                            const mergedArray = matchingResult.map(matchingItem => {
                                // 같은 이름을 가진 모든 튜터 매칭
                                const matchingTutor = matchingTutorInfo.find(
                                    resultItem => resultItem.tutorName === matchingItem.tutor_name,
                                )

                                if (matchingTutor) {
                                    return {
                                        ...matchingItem,
                                        start_date: matchingTutor.startDate,
                                        end_date: matchingTutor.endDate,
                                        is_delete: false,
                                    }
                                }
                                return null
                            })

                            const filteredArray = mergedArray.filter(item => item !== null)

                            try {
                                const programInstructorMatchingResult =
                                    await postProgramInstructorMatching(filteredArray)
                                console.log('programInstructorMatchingResult:', programInstructorMatchingResult)
                            } catch (error) {
                                console.error('Error in postProgramInstructorMatching:', error)
                            }
                        }
                    }
                    setModalOpen(true)
                } else if (response.code === 'ERR_BAD_REQUEST') {
                    errorMessage(registerError)
                } else {
                    errorMessage(registerError)
                }
            } catch (error) {
                console.error('프로그램 목록을 불러오는 동안 오류가 발생했습니다.', error)
                return null
            }
        }
        return false
    }

    const handleRegisterInventoryAPI = async () => {
        if (fileName === '') {
            setAlertOpen(true)
        } else {
            try {
                const response = await insertToolsInventory(modifiedData, regionId)
                if (response && response.status === 201) {
                    setModalOpen(true)
                } else {
                    errorMessage(registerError)
                }
            } catch (error) {
                console.error('재고 목록을 불러오는 동안 오류가 발생했습니다.', error)
            }
        }
    }

    /**
     * URL에 따라 분기처리
     */
    const handleConfirmRegisterURLPath = () => {
        if (isTutorURL) {
            if (hasDuplicateTutorName) {
                errorMessage(duplicatedError)
            } else {
                handleRegisterTutorAPI()
            }
        } else if (isProgramURL) {
            if (unRegisteredTutorName) {
                errorMessage(unregisterError)
            } else {
                handleRegisterProgramAPI()
            }
        } else if (isInventoryURL) {
            if (requiredNull) {
                errorMessage(requiredNullError)
            } else {
                handleRegisterInventoryAPI()
            }
        }
    }

    const getTitle = pathName => {
        const pathMap = {
            program: `프로그램`,
            tutors: `강사`,
            inventory: `재고 관리`,
            rental: `대여관리`,
            // statistics: ``,
            // report: ``,
            // survey: ``,
            // accounts: ``,
        }

        return pathMap[pathName] || ''
    }

    return (
        <div className={styles.content_area}>
            <div className={styles.register_area}>
                <span className="h4">
                    {paths[0] === 'tools' ? (paths[1] === 'inventory' ? '재고' : '대여반납') : getTitle(paths[0])} 정보
                    등록
                </span>
                <div className={styles.required_input_info}>
                    <div className="body2">
                        {paths[0] === 'tools' ? (paths[1] === 'inventory' ? '재고' : '대여반납') : getTitle(paths[0])}{' '}
                        정보는 필수입력사항입니다.
                    </div>
                    <div className="body2">
                        [양식다운로드] 버튼을 클릭하신 후 엑셀양식을 받아서 이용하시면 편리하게 사용할 수 있으며 항목
                        변경에 따른 실수를 방지할 수 있습니다.
                    </div>
                </div>
                <ColorButton
                    override={blue['900']}
                    icon={<DownloadIcon24 />}
                    size="download"
                    onClick={() => handleDownloadForm()}
                >
                    양식 다운로드
                </ColorButton>
            </div>
            <div className={styles.upload_area_wrap}>
                <DraggableUpload
                    name="file"
                    accept=".xlsx, .xls"
                    handleFileUpload={handleFileUpload}
                    list={jsonData}
                    fileName={fileName}
                    handleDeleteFile={handleDeleteFile}
                />
            </div>
            {jsonData.length > 0 && <ListView list={jsonData} />}
            <Flex justify="center" align="center" gap={8}>
                <ColorButton size="large" type="primary" onClick={() => handleConfirmRegisterURLPath()}>
                    등록
                </ColorButton>
                <Link
                    to={
                        isProgramURL
                            ? '/program'
                            : isTutorURL
                            ? '/tutors'
                            : isInventoryURL
                            ? '/tools/inventory'
                            : isRentalURL
                            ? '/tools/rental'
                            : ''
                    }
                >
                    <ColorButton size="large" override={blue['900']}>
                        취소
                    </ColorButton>
                </Link>
            </Flex>
        </div>
    )
}

export default Register
