import React from 'react'
import dayjs from 'dayjs'
import { DatePicker, ConfigProvider } from 'antd'
import locale from 'antd/locale/ko_KR'
import 'dayjs/locale/ko'

import styles from './datepicker.module.scss'

const DatePickerInput = ({ value = null, onChange, name, noMt = false }) => {
    const displayFormat = 'YYYY-MM-DD'
    const internalFormat = 'YYYYMMDD'

    const displayValue = value ? dayjs(value, internalFormat).format(displayFormat) : null
    const handleDateChange = (date, dateString) => {
        const internalDate = dateString ? dayjs(dateString, displayFormat).format(internalFormat) : null
        onChange(internalDate)
    }
    const dateRender = current => {
        const style = {}
        if (current.day() === 0 || current.day() === 6) {
            style.color = '#EC2B00'
        }

        if (current.format(internalFormat) === value) {
            style.backgroundColor = '#024383'
            style.color = '#fff'
        }

        return (
            <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
            </div>
        )
    }

    return (
        <div className={styles.area}>
            <ConfigProvider locale={locale}>
                <DatePicker
                    name={name}
                    className={`${styles.datepicker} ${noMt ? styles.no_mt : ''}`}
                    cellRender={dateRender}
                    showToday={null}
                    defaultValue={displayValue ? dayjs(displayValue, displayFormat) : null}
                    format={displayFormat}
                    onChange={handleDateChange}
                    mode="date"
                />
            </ConfigProvider>
        </div>
    )
}

export default DatePickerInput
